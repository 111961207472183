import type AutocompleteElement from '@github/auto-complete-element'
import {dialog} from '@github-ui/details-dialog'
import {loaded} from '@github-ui/document-ready'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {requestSubmit} from '@github-ui/form-utils'

// Generic preview functionality for enterprise account custom messages
on('click', '.js-preview-message', function (event) {
  const inputElement =
    document.querySelector<HTMLInputElement>('#custom_message_input_value') ||
    document.querySelector<HTMLTextAreaElement>('#custom_message_input_value')
  const message = inputElement!.value
  document.querySelector<HTMLInputElement>('#custom_message_preview_value')!.value = message
  const button = event.currentTarget as HTMLButtonElement
  button.form!.submit()
})

on('click', '.js-admin-settings-policy-input', function (event) {
  const input = event.currentTarget as HTMLInputElement
  const form = input.form!

  const submitter = form.querySelector<HTMLButtonElement>('[aria-checked="true"]')!

  requestSubmit(form, submitter)
})

function businessSamlForm(): HTMLFormElement {
  return document.querySelector<HTMLFormElement>('.js-business-saml-provider-settings-form')!
}

function businessSamlFormInputs(): HTMLElement {
  return businessSamlForm().querySelector<HTMLElement>('.js-business-saml-form-inputs')!
}

function businessSamlCurrentlyEnabled(): boolean {
  return document.querySelector<HTMLInputElement>('.js-business-saml-currently-enabled')!.value === '1'
}

function businessSamlProvisioningSettings(): HTMLElement {
  return document.querySelector<HTMLElement>('.js-business-saml-user-provisioning')!
}

function enablingBusinessSaml(): boolean {
  return document.querySelector<HTMLInputElement>('.js-business-enable-saml')!.checked
}

function disablingBusinessSaml(): boolean {
  return businessSamlCurrentlyEnabled() && !enablingBusinessSaml()
}

// The HTMLInputElement representing which form button was clicked by the user.
// This element is added to the page by the github/remote-submit.js module.
function submitAction(): HTMLInputElement {
  return document.querySelector<HTMLInputElement>('.js-submit-button-value')!
}

// Did the user click the "Test SAML Configuration" button?
function testingSettings(): boolean {
  return submitAction().name === 'test_settings'
}

// Did the user click the "Save" button?
function savingSettings(): boolean {
  return submitAction().name === 'save_settings'
}

// Show a warning/confirmation dialog when disabling SAML.
async function showDisableSamlWarning() {
  const confirmation = await dialog({
    content: document
      .querySelector<HTMLTemplateElement>('#disable-saml-confirmation')!
      .content.cloneNode(true) as DocumentFragment,
  })

  confirmation.addEventListener('dialog:remove', restoreSamlState)
}

// Show a warning/confirmation dialog when disabling SAML.
async function showEnableSamlDeprovisioningWarning() {
  const confirmation = await dialog({
    content: document
      .querySelector<HTMLTemplateElement>('#enable-saml-deprovisioning-confirmation')!
      .content.cloneNode(true) as DocumentFragment,
  })

  confirmation.addEventListener('dialog:remove', restoreSamlDeprovisioningState)
}

// Submit the form using the native .submit() function.
// Use this when you *really* want to submit the form and avoid clashes
// with the github `submit()` helper.
function submitBusinessSamlForm() {
  businessSamlForm().submit()
}

;(async function () {
  await loaded
})()

// Show the SAML provider settings form and disable the save button till settings
// are tested when the "Enable SAML" checkbox is checked. Hide the form and enable
// the save button when the "Enable SAML" checkbox is unchecked.
on('click', '.js-business-enable-saml', function (event) {
  const input = event.currentTarget as HTMLInputElement
  if (input.checked) {
    businessSamlFormInputs().hidden = false
    businessSamlProvisioningSettings().hidden = false
    disableBusinessSAMLSaveSubmit()
  } else {
    businessSamlFormInputs().hidden = true
    businessSamlProvisioningSettings().hidden = true
    enableBusinessSAMLSaveSubmit()
  }
})

// Explicitly controls the flow of events required to submit the security
// settings form in the correct state:
// -  Prevent the default 'submit' event from firing too early
// -  Set a hidden input value to distinguish between
//    "Test settings" and "Save" buttons on the server
// -  Disable the save changes warning, because we're about to submit the form
// -  Fire the 'submit' event on the form
on('click', '.js-business-saml-submit', function (event) {
  event.preventDefault()
  const button = event.currentTarget as HTMLButtonElement
  requestSubmit(businessSamlForm(), button)
})

// Handle submitting the form.
// Depending on the user's choices, we handle these states:
//
// 1. Testing SAML settings
// 2. Saving SAML settings:
//    2.1 Disabling SAML:
//     - Show a warning and prompt for confirmation
//    2.2 Submit the form
//
on('submit', '.js-business-saml-provider-settings-form', function (event) {
  event.preventDefault()
  if (testingSettings()) {
    submitBusinessSamlForm()
  } else if (savingSettings()) {
    if (disablingBusinessSaml()) {
      showDisableSamlWarning()
    } else {
      submitBusinessSamlForm()
    }
  }
})

on('submit', '.js-enterprise-user-provisioning-form', function (event) {
  const previousValue = event.currentTarget.querySelector<HTMLInputElement>(
    '[name="business_saml_provider_saml_deprovisioning_enabled_previously"]',
  )!
  const checkbox = event.currentTarget.querySelector<HTMLInputElement>(
    '#business_saml_provider_saml_deprovisioning_enabled',
  )!

  if (previousValue.value === '0' && checkbox.checked) {
    event.preventDefault()
    showEnableSamlDeprovisioningWarning()
  }
})

function restoreSamlState() {
  restoreState('.js-business-enable-saml')
}

// Restore the "enabled" state of the UI, if the user cancels the "disable"
// dialog
function restoreState(selector: string) {
  const checkbox = document.querySelector(selector)
  if (checkbox && checkbox instanceof HTMLInputElement) {
    checkbox.checked = true
    businessSamlFormInputs().hidden = false
    businessSamlProvisioningSettings().hidden = false
  }
}

// when enabling of SAML deprovisioning is cancelled restore the unchecked state of the checkbox
function restoreSamlDeprovisioningState() {
  const checkbox = document.querySelector<HTMLInputElement>('#business_saml_provider_saml_deprovisioning_enabled')!
  checkbox.checked = false
}

on('click', '.js-enable-enable-saml-provider-button', function () {
  const button = document.querySelector<HTMLButtonElement>('.js-enable-saml-provider-button')
  const instruction = document.querySelector<HTMLElement>('.js-enable-saml-provider-instruction')
  if (button && button instanceof HTMLButtonElement) {
    button.disabled = false
  }
  if (instruction && instruction instanceof HTMLElement) {
    instruction.hidden = true
  }
})

// OIDC settings

on('click', '.js-enable-enable-oidc-provider-button', function () {
  const button = document.querySelector<HTMLButtonElement>('.js-enable-oidc-provider-button')!
  const instruction = document.querySelector<HTMLElement>('.js-enable-oidc-provider-instruction')!
  button.disabled = false
  instruction.hidden = true
})

// Show the details of the suggested org in the adjacent box when a suggested
// org is selected.
on('click', '.js-suggested-org', function (event) {
  const target = event.currentTarget as Element
  if (target.getAttribute('aria-disabled') === 'true') return

  const form = target.closest<HTMLElement>('form')!
  const detailsSource = target.querySelector<HTMLElement>('.js-suggested-org-details-source')!
  const detailsTarget = form.querySelector<HTMLElement>('.js-suggested-org-details-target')!
  detailsTarget.innerHTML = detailsSource.innerHTML
})

// Ensure that the details of a previously selected org are cleared from the
// details target if the value of the auto-complete element is unset.
on('change', '.js-invite-business-org-auto-complete', function (event) {
  const completer = event.currentTarget as AutocompleteElement

  const form = completer.closest<HTMLElement>('form')!
  const detailsTarget = form.querySelector<HTMLElement>('.js-suggested-org-details-target')!
  if (!completer.value) {
    detailsTarget.textContent = ''
  }
})

// Disable business SAML save submit button.
function disableBusinessSAMLSaveSubmit() {
  const button = document.querySelector<HTMLButtonElement>('.js-business-saml-save-submit')!
  button.disabled = true
}

// Enable business SAML save submit button.
function enableBusinessSAMLSaveSubmit() {
  const button = document.querySelector<HTMLButtonElement>('.js-business-saml-save-submit')!
  button.disabled = false
}

on(
  'details-menu-selected',
  '.js-to-enterprise-menu',
  function (event) {
    const toEnterpriseName = (event.detail.relatedTarget as Element).querySelector<HTMLElement>(
      'span[data-menu-button-contents]',
    )!.textContent

    for (const el of document.querySelectorAll<HTMLElement>('.js-to-enterprise-name')) {
      el.textContent = toEnterpriseName
    }
  },
  {capture: true},
)

on('click', '.js-try-emu-toggle-text', function () {
  for (const el of document.querySelectorAll<HTMLElement>('.js-try-emu-more-text')) {
    if (el.style.display !== 'none') {
      el.style.display = 'none'
    } else {
      el.style.display = 'block'
    }
  }
})
